import React from "react";
import { useRouter } from "next/router";
import PageNotFound from "../components/page-not-found";
import dynamic from "next/dynamic";
import localized404Text from "../utils/locale/404.json";
import config from "../utils/env";

const RiotBar = dynamic(import("@riotgames/riotbar-react"), { ssr: false });

const localizedText: { [key: string]: { title: string; linkText: string } } =
  localized404Text;

const Riot404Page = (props: {
  setRiotbarProductId: string;
  setEnvironment: string;
}) => {
  const { setRiotbarProductId, setEnvironment } = props;
  const router = useRouter();
  const locale =
    router.locale && router.locale in localizedText ? router.locale : "en-us";

  if (!setRiotbarProductId) {
    throw new Error(
      "RIOTBAR_PRODUCT_ID is not set. Please set the environment variable."
    );
  }

  return (
    <>
      <header>
        {/* Temporary needded for sites that place riotbar in specific places */}
        <div id="riotbar-header" />
      </header>
      <RiotBar
        key={`riotbar`}
        product={setRiotbarProductId}
        locale={locale}
        environment={setEnvironment}
      />
      <PageNotFound
        errorCode="404"
        title={localizedText[locale]?.title}
        linkText={localizedText[locale].linkText}
        locale={locale}
      />
      <footer>
        {/* Temporary needded for sites that place riotbar in specific places */}
        <div id="riotbar-footer" />
      </footer>
    </>
  );
};

/**
 * Use ISR on the 404 page to bypass a netlify limitation
 * of not being able to fetch a statically generated 404 during ISR.
 * @returns
 */
export async function getStaticProps() {
  const setEnvironment = config.SITE_ENV;
  const setRiotbarProductId = config.RIOTBAR_PRODUCT_ID;

  return {
    props: {
      setEnvironment,
      setRiotbarProductId,
    },
    revalidate: 60, // This TTL will be used internally to cache responses.
  };
}

export default Riot404Page;
