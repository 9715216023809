import React from "react";
import styles from "./page-not-found.module.css";
import Link from "next/link";

export default function PageNotFound({
  errorCode,
  title,
  linkText,
  locale,
}: {
  errorCode: string;
  title: string;
  linkText: string;
  locale: string;
}) {
  return (
    <div className={styles.notFoundWrapper}>
      <p className={styles.errorCode}>{errorCode}</p>
      <h1 className={styles.notFoundTitle}>{title}</h1>
      <Link href={`/${locale}/`} className={styles.notFoundText}>
        {linkText}
      </Link>
    </div>
  );
}
